<template>
  <vx-card title="Home">
    <p>Aquí se puede modificar la información de la página principal.</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mt-5">
      <!---First section-->
      <h6>Primera sección</h6>
      <hr class="mt-2 mb-2">
      <!--Title-->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input @blur="content.home.title= trimString(content.home.title)"
                    v-validate="'required|min:20|max:100'"
                    name="title"
                    class="w-full" label="Titulo"
                    v-model="content.home.title"/>
          <span class="text-danger text-sm"
                v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
      </div>
      <!--End title-->
      <!--Description-->
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-textarea @blur="content.home.description= trimString(content.home.description)"
                       v-validate="'required|min:10|max:350'"
                       name="descripction" label="Descripción"
                       v-model="content.home.description"/>
          <span class="text-danger text-sm"
                v-show="errors.has('descripction')">{{ errors.first('descripction') }}</span>
        </div>
      </div>
      <!--End description-->
      <!--End-->

      <!--Second section-->
      <h6>Segunda sección</h6>
      <hr class="mt-2 mb-2">
      <!--Title-->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input @blur="content.home.title2= trimString(content.home.title2)"
                    v-validate="'required|min:10|max:100'"
                    name="title2"
                    class="w-full" label="Titulo"
                    v-model="content.home.title2"/>
          <span class="text-danger text-sm"
                v-show="errors.has('title2')">{{ errors.first('title2') }}</span>
        </div>
      </div>
      <!--End title-->
      <!--Description-->
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-textarea @blur="content.home.description2= trimString(content.home.description2)"
                       v-validate="'required|min:10|max:350'"
                       name="descripction2" label="Descripción"
                       v-model="content.home.description2"/>
          <span class="text-danger text-sm"
                v-show="errors.has('descripction')">{{ errors.first('descripction') }}</span>
        </div>
      </div>
      <!--End description-->
      <!--End-->
      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import trimString from "../mixins/trimString";

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()

export default {
  name: "Content",
  mixins: [
    trimString
  ],
  data() {
    return {
      content: {
        home: {}
      },
      progress: false,
      initProgress: false
    }
  },
  created() {
    this.initProgress = true
    db.collection("content").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.content = {
          id: doc.id,
          ...doc.data()
        }
      })
      this.initProgress = false
    })
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    }
  },
  methods: {
    async submitData() {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Content
          let contentRef = db.collection('content').doc(this.content.id)
          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id
          await contentRef.update({
            ...cloneContent,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          })
          this.$vs.notify({
            color: 'success',
            title: 'Contenido',
            text: 'Contenido modificado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style scoped>

</style>
